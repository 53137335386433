import React, { Component } from 'react';
import SignUpFormBase from './SignUpFormBase';
import SignUpLink from './SignUpLink';

import style from './Signup.scss';

class SignUp extends Component {
  render() {
    return (
      <div className={style.register}>
        <div className={style.image} />
        <div className={style.content}>
          <div className={style.inner}>
            <div className={style.header}>
              <div className={style.title}>Sign Up</div>
            </div>
            <div className={style.fields}>
              <div className={style.form}>
                <SignUpFormBase />
              </div>
            </div>
            <div className={style.login2}>
              <SignUpLink />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SignUp;
